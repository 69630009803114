import { ROUTES } from '@/router/routesEnum';
import gspService, { GspService } from '@/shared/model/gspService';
import { OdataItems } from '@/shared/model/OdataItems';
import { SearchParams } from '@/shared/model/searchParams';
import { CONST } from '@/shared/utils/Constants';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
// import { DefaultViewHelper } from '../ViewHelper';
import GeneralUtils from '@/shared/utils/generalUtils';
import D4yTable from '@/components/d4y-table/d4y-table.vue';

const name = 'gspServices-view';
const gspServiceModule = namespace('gspService');

@Component({ name: name, components: { D4yTable } })
export default class GspServicesComponent extends Vue {
  @gspServiceModule.Action('getGspServices') private actionGetgspServices!: any;
  @gspServiceModule.Getter('getGspServices')
  private gspServices!: OdataItems<GspService>;
  @gspServiceModule.Getter('getGspServicesSearchParams')
  private gspServicesSearchParams!: SearchParams;
  @gspServiceModule.Getter('getGspServicesIsLoading')
  private gspServicesIsLoading!: boolean;
  @gspServiceModule.Action('deleteGspService')
  private actionDeleteGspService!: any;

  async mounted() {
    var promiseAll = [this.getGspServices()];
    await Promise.all(promiseAll);
  }
  private async getGspServices() {
    await this.actionGetgspServices({ searchParams: this.gspServicesSearchParams });
  }
  get headers() {
    let headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
      align?: string;
    }[] = [
      { text: this.$t('name'), value: 'name' },
      { text: this.$t('modules'), value: 'service.modules', sortable: false },
      {
        text: this.$t('actions'),
        value: 'actions',
        sortable: false,
        width: '1%',
        align: 'center',
      },
    ];
    return headers;
  }

  get image() {
    return {};
    return {
      src: 'img_gspService.png',
      header: this.$t('title'),
      width: 360,
      height: 200,
    };
  }

  onAdd() {
    this.add();
  }

  add() {
    this.$emit("add-edit", {type: 'service', id: 0})
    // this.$router.push({
    //   name: ROUTES.gspService,
    //   params: {
    //     gspServiceId: CONST.emptyGuid,
    //   },
    // });
  }

  editGspService(editGspService: GspService) {
    this.$emit("add-edit", {type: 'service', id: editGspService.id})
    // this.$router.push({
    //   name: ROUTES.gspService,
    //   params: { gspServiceId: editGspService.id },
    // });
  }

  deleteGspService(editedGspService: GspService) {
    this.$confirm
      .open(
        `${this.$t('delete_dialog_title')}`,
        `${this.$t('delete_dialog_text')}`,
        {
          cancelText: this.$t('delete_dialog_cancel'),
          okText: this.$t('delete_dialog_ok'),
        }
      )
      .then(async (response: any) => {
        if (response) {
          await this.actionDeleteGspService(editedGspService.id).then(() => {
            this.getGspServices();
          });
        }
      });
  }

  get isRealXl() {
    return this.$vuetify.breakpoint.width < 1920;
  }

  get getFilterFields() {
    return this.gspServicesSearchParams.orClauseFieldsIds
      .map(x => this.$t(GeneralUtils.camelToSnakeCase(x)))
      .toString();
  }

  private async gspServicesUpdateOptions(newVal: any, oldVal: any) {
    this.gspServicesSearchParams.dataOption = newVal;
    this.gspServicesSearchParams.filter = newVal.filter;
    if (newVal != oldVal && !this.gspServicesIsLoading) this.getGspServices();
  }
}
