import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';

const logger = new Logger('login');

@Component({
  components: {},
})
export default class LoginView extends Vue {}
