import { Component, Ref, Vue } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import gspRole, { GspRole } from '@/shared/model/gspRole';
// import { AUTHORITIES } from '@/shared/store/modules/auth';
import D4yEdit from '@/components/d4y-edit/d4y-edit.vue';
import D4yTable from '@/components/d4y-table/d4y-table.vue';
import { CONST } from '@/shared/utils/Constants';
import { ROUTES } from '@/router/routesEnum';
import DateUtils from '@/shared/utils/DateUtils';
import GeneralUtils from '@/shared/utils/generalUtils';
import GspRoleDataDialog from './gsp-role-data-dialog/gsp-role-data-dialog.vue';
import GspDialog from '../gsp-dialog/gsp-dialog.vue';

const name = 'gspRole-edit-dialog';
const logger = new Logger(name);
const gspRoleManagement = namespace('gspRole');
const gspRoleDataModule = namespace('gspRoleData');
const companyModule = namespace('company');
const gspServiceModule = namespace('gspService');

@Component({
  name: name,
  components: {
    D4yEdit,
    D4yTable,
    GspRoleDataDialog,
    GspDialog,
  },
})
export default class GspRoleEditDialog extends Vue {
  @gspRoleManagement.Action('getGspRole') private actionGetGspRole!: any;
  @gspRoleManagement.Action('updateGspRole')
  private actionUpdateGspRole!: any;
  // @gspRoleManagement.Getter('getGspRoleRoles')
  // private roles!: any;

  @gspRoleDataModule.Action('getGspRolesData')
  private actionGetGspRolesData!: any;
  @gspRoleDataModule.Action('deleteGspRoleData')
  private actionDeleteGspRoleData!: any;
  @gspRoleDataModule.Action('resetGspRoleData')
  private actionResetGspRoleData!: any;

  @gspRoleDataModule.Getter('getGspRolesData')
  private getGspRolesData!: any;
  @gspRoleDataModule.Getter('getGspRolesDataSearchParams')
  private gspRolesDataSearchParams!: any;

  @companyModule.Getter('getCompanyUserZrs')
  private companyUserZrs!: any;

  selected = [];
  selectedModules = [];
  selectedAttributes = [];

  async mounted() {
    const gspRoleId = this.$route.params['gspRoleId'];
    //CONST.emptyGuid;
    if (gspRoleId != '0') {
      this.getGspRole();
    } else {
      this.gspRole = gspRole.parse({});
      this.actionResetGspRoleData();
    }
  }
  gspRoleId = 0;
  getGspRole(id: any = 0) {
    const gspRoleId = id || this.$route.params['gspRoleId'];
    this.gspRoleId = gspRoleId;
    this.actionGetGspRole(gspRoleId).then((response: GspRole) => {
      this.gspRole = Object.assign({}, response);
      this.titleInfo = this.gspRole.name;

      this.actionGetGspRolesData({ searchParams: this.gspRolesDataSearchParams, roleId: gspRoleId })
        .then((result: any) => {
          console.log('result :>> ', result);
          // this.gspRoleData.items = result.value;
        })
        .catch((err: any) => {
          logger.error(err);
        });
    });
  }
  private gspRole: GspRole = gspRole.parse({});

  @Ref('observer-add-edit-gspRole-form') private observerForm!: any;

  activeTab = 0;
  isLoading = false;
  titleInfo = 'empty';

  get isEmptyGspRole() {
    return +this.gspRole.id == 0;
  }

  get image() {
    return {};
    return {
      src: '', //'img_gspRole.png',
      header: '',
      width: 350,
      height: 200,
    };
  }

  async onClickedUpdate() {
    this.isLoading = true;
    const result = await this.observerForm.validate();
    if (!result) {
      this.isLoading = false;
      return;
    }
    const payload: any = this.gspRole;
    payload.selected = this.selected;
    console.log('onClickedUpdate this.selected :>> ', this.selected);
    await this.actionUpdateGspRole(payload).then((resp: GspRole) => {
      this.isLoading = false;
      this.getGspRole(resp.id);
      console.log('this.$router.currentRoute :>> ', this.$router.currentRoute);
      // this.$router.currentRoute.params = {gspRoleId: resp.id+''};
      // this.$route.params ={gspRoleId: resp.id+''};
      this.$router.replace({
        name: ROUTES.gspRole,
        params: { gspRoleId: resp.id + '' },
      });
    });
  }

  onClickedClose() {
    this.$router.push({
      name: ROUTES.gspRoles,
    });
  }

  private async getGspRoleData() {
    // await this.actionGetGspRoleData({ searchParams: this.gspRoleDataSearchParams });
  }
  get headers() {
    let headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
      align?: string;
    }[] = [
      { text: this.$t('service'), value: 'service.name', sortable: false },
      { text: this.$t('module'), value: 'service.modules', sortable: false },
      { text: this.$t('attributes'), value: 'service.attributes', sortable: false },
      {
        text: this.$t('actions'),
        value: 'actions',
        sortable: false,
        width: '1%',
        align: 'center',
      },
    ];
    return headers;
  }

  onAdd() {
    // this.add();
    this.showGspRoleDataDialog();
  }

  add() {
    this.$emit('add-edit', { type: 'service', id: 0 });
    // this.$router.push({
    //   name: ROUTES.gspService,
    //   params: {
    //     gspServiceId: CONST.emptyGuid,
    //   },
    // });
  }

  editGspRoleData(editGspRoleData: any) {
    this.$emit('add-edit', { type: 'service', id: editGspRoleData.id });
    // this.$router.push({
    //   name: ROUTES.gspService,
    //   params: { gspServiceId: editGspRoleData.id },
    // });
  }

  deleteGspRoleData(editedGspRoleData: any) {
    this.$confirm
      .open(`${this.$t('delete_dialog.title')}`, `${this.$t('delete_dialog.text')}`, {
        cancelText: this.$t('delete_dialog.cancel'),
        okText: this.$t('delete_dialog.ok'),
      })
      .then(async (response: any) => {
        if (response) {
          await this.actionDeleteGspRoleData(editedGspRoleData.id).then(() => {
            this.actionGetGspRolesData({ searchParams: this.gspRolesDataSearchParams, roleId: this.gspRoleId });
          });
        }
      });
  }

  get isRealXl() {
    return this.$vuetify.breakpoint.width < 1920;
  }

  gspRoleDataIsLoading = false;
  // gspRoleDataSearchParams: any = {};
  // gspRoleData= { items: []};

  get gspRoleData() {
    return this.getGspRolesData;
  }

  @gspServiceModule.Getter('getGspServicesSearchParams')
  private gspRoleDataSearchParams!: any; // SearchParams;

  get getFilterFields() {
    return this.gspRoleDataSearchParams.orClauseFieldsIds
      .map((x: any) => this.$t(GeneralUtils.camelToSnakeCase(x)))
      .toString();
  }

  private async gspRoleDataUpdateOptions(newVal: any, oldVal: any) {
    this.gspRoleDataSearchParams.dataOption = newVal;
    this.gspRoleDataSearchParams.filter = newVal.filter;
    if (newVal != oldVal && !this.gspRoleDataIsLoading) this.getGspRoleData();
  }

  //#region Gsp-Role-data-dialog
  gspRoleDataDialog = {
    show: false,
    model: {},
    OnClose: () => {
      this.gspRoleDataDialog.show = false;
    },
  };

  showGspRoleDataDialog() {
    logger.log('show-gsp-role-data-dialog');
    console.log('showGspRoleDataDialog :>> ');
    this.gspRoleDataDialog.model = this.gspRole;
    this.gspRoleDataDialog.show = true;
  }

  //#endregion
}
