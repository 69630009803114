import { ROUTES } from '@/router/routesEnum';
import gspRole, { GspRole } from '@/shared/model/gspRole';
import { OdataItems } from '@/shared/model/OdataItems';
import { SearchParams } from '@/shared/model/searchParams';
import { CONST } from '@/shared/utils/Constants';
import { Logger } from 'fsts';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { DefaultViewHelper } from '../ViewHelper';
import GeneralUtils from '@/shared/utils/generalUtils';
import D4yTable from '@/components/d4y-table/d4y-table.vue';
import GspDialog from './gsp-dialog/gsp-dialog.vue';

const name = 'gspRoles-view';
const logger = new Logger(name);
const gspRoleModule = namespace('gspRole');
const gspAttributeModule = namespace('gspAttribute');
const gspModuleModule = namespace('gspModule');
const gspServiceModule = namespace('gspService');
// gspAttribute,
// gspModule,
// gspService,


@Component({ name: name, components: { D4yTable, GspDialog } })
export default class GspRolesComponent extends Vue {
  @gspRoleModule.Action('getGspRoles') private actionGetgspRoles!: any;
  @gspRoleModule.Getter('getGspRoles')
  private gspRoles!: OdataItems<GspRole>;
  @gspRoleModule.Getter('getGspRolesSearchParams')
  private gspRolesSearchParams!: SearchParams;
  @gspRoleModule.Getter('getGspRolesIsLoading')
  private gspRolesIsLoading!: boolean;
  @gspRoleModule.Action('deleteGspRole')
  private actionDeleteGspRole!: any;


  // @gspAttributeModule.Action('getGspAttributes') private actionGetGspAttributes!: any;
  // @gspModuleModule.Action('getGspModules') private actionGetGspModules!: any;
  // @gspServiceModule.Action('getGspServices') private actionGetGspServices!: any;

  async mounted() {
    var promiseAll = [this.getGspRoles()];
    // var promiseAll = [this.getGspRoles()
    // ,this.actionGetGspServices(), this.actionGetGspModules(), this.actionGetGspAttributes()];
    await Promise.all(promiseAll);
  }
  private async getGspRoles() {
    await this.actionGetgspRoles({ searchParams: this.gspRolesSearchParams });
  }
  get headers() {
    let headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
      align?: string;
    }[] = [
      { text: this.$t('name'), value: 'name' },
      { text: this.$t('created_on'), value: 'createdDate' },
      {
        text: this.$t('actions'),
        value: 'actions',
        sortable: false,
        width: '1%',
        align: 'center',
      },
    ];
    return headers;
  }

  get image() {
    return {
      src:  '',//'img_gspRole.png',
      header: this.$t('title'),
      width: 360,
      height: 200,
    };
  }

  onAdd() {
    this.add();
  }

  add() {
    this.$router.push({
      name: ROUTES.gspRole,
      params: {
        gspRoleId: '0',
      },
    });
  }

  editGspRole(editGspRole: GspRole) {
    this.$router.push({
      name: ROUTES.gspRole,
      params: { gspRoleId: editGspRole.id+'' },
    });
  }

  deleteGspRole(editedGspRole: GspRole) {
    this.$confirm
      .open(
        `${this.$t('delete_dialog_title')}`,
        `${this.$t('delete_dialog_text')}`,
        {
          cancelText: this.$t('delete_dialog_cancel'),
          okText: this.$t('delete_dialog_ok'),
        }
      )
      .then(async (response: any) => {
        if (response) {
          await this.actionDeleteGspRole(editedGspRole.id).then(() => {
            this.getGspRoles();
          });
        }
      });
  }

  get isRealXl() {
    return this.$vuetify.breakpoint.width < 1920;
  }

  get getFilterFields() {
    return this.gspRolesSearchParams.orClauseFieldsIds
      .map(x => this.$t(GeneralUtils.camelToSnakeCase(x)))
      .toString();
  }

  private async gspRolesUpdateOptions(newVal: any, oldVal: any) {
    this.gspRolesSearchParams.dataOption = newVal;
    this.gspRolesSearchParams.filter = newVal.filter;
    if (newVal != oldVal && !this.gspRolesIsLoading) this.getGspRoles();
  }


    //#region Gsp-dialog
    dialogGsp = {
      show: false,
      model: {},
      OnClose: () => {
        this.dialogGsp.show = false;
      },
    };

    showGspDialog() {
      logger.log('show-gsp-dialog');
      this.dialogGsp.show = true;
    }

    //#endregion
}
