var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"px-sm-8 px-md-15 px-xl-0",attrs:{"fluid":_vm.isRealXl}},[_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","lg":"11"}},[_c('v-card',[_c('validation-observer',{ref:"observer-add-edit-gspRole-form",scopedSlots:_vm._u([{key:"default",fn:function(v){return [_c('v-form',{attrs:{"id":"editForm","data-vv-scope":"editForm"}},[_c('v-card-text',[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-tabs',{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tab',[_vm._v(" "+_vm._s(_vm.$t('data'))+" ")])],1),_c('v-tabs-items',{staticClass:"mt-3",model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tab-item',[_c('v-row',{attrs:{"wrap":""}},[_c('v-col',{staticClass:"pt-6",attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('name'),"rules":"max:32|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","label":_vm.$t('name'),"name":"name","outlined":"","error-messages":errors[0]},model:{value:(_vm.gspRole.name),callback:function ($$v) {_vm.$set(_vm.gspRole, "name", $$v)},expression:"gspRole.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"7"}}),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4"}},[_c('v-textarea',{attrs:{"outlined":"","hide-details":"","name":"description","label":_vm.$t('description')},model:{value:(_vm.gspRole.description),callback:function ($$v) {_vm.$set(_vm.gspRole, "description", $$v)},expression:"gspRole.description"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm7":"","md7":""}},[_c('d4y-table',{attrs:{"isShowSearchField":false,"allowAdd":_vm.gspRoleId,"allowEdit":false,"allowDelete":true,"image":_vm.image,"items":_vm.gspRoleData.items,"headers":_vm.headers,"options":_vm.gspRoleDataSearchParams.dataOption,"filterFields":_vm.getFilterFields,"isNameAsRef":true},on:{"action:add":_vm.onAdd,"click:avatar":_vm.editGspRoleData,"click:edit":_vm.editGspRoleData,"click:row":_vm.editGspRoleData,"click:delete":_vm.deleteGspRoleData,"update:options":_vm.gspRoleDataUpdateOptions},scopedSlots:_vm._u([{key:"service.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.service.name)+" ")]}},{key:"service.modules",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.module.name)+" ")]}},{key:"service.attributes",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.attributes.map(function (x){ return x.name; }).join(','))+" ")]}}],null,true)})],1)],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"pt-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"elevation":"0","color":"error"},on:{"click":_vm.onClickedClose}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-btn',{attrs:{"elevation":"0","dark":"","color":"primary","disabled":_vm.isLoading,"loading":_vm.isLoading},on:{"click":_vm.onClickedUpdate}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1)]}}])})],1)],1)],1)],1),(_vm.gspRoleDataDialog.show)?_c('gsp-role-data-dialog',{attrs:{"model":_vm.gspRoleDataDialog.model,"dialog":_vm.gspRoleDataDialog.show},on:{"click:close":_vm.gspRoleDataDialog.OnClose}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }