import { CONST } from '@/shared/utils/Constants';
import { ROUTES } from '@/router/routesEnum';
import { Component, Vue } from 'vue-property-decorator';
import DateField from '@/components/_common/date-field/DateField.vue';
import { namespace } from 'vuex-class';
import { OdataItems } from '@/shared/model/OdataItems';
import { TblUpdateSupplier } from '@/shared/model/tblUpdateSupplier';
import { TblUpdateCondition } from '@/shared/model/tblUpdateCondition';
import { TblUpdateDocument } from '@/shared/model/tblUpdateDocument';
import { SearchParams } from '@/shared/model/searchParams';
import { CompanyWrGruppenResult } from '@/shared/model/company';
import GeneralUtils from '@/shared/utils/generalUtils';

const tblUpdateSupplierModule = namespace('tblUpdateSupplier');
const tblUpdateConditionModule = namespace('tblUpdateCondition');
const tblUpdateDocumentModule = namespace('tblUpdateDocument');
const authModule = namespace('auth');

@Component({
  components: { DateField },
})
export default class TasksDashboard extends Vue {
  @tblUpdateSupplierModule.Action('getTblUpdateSuppliers')
  private actionGettblUpdateSuppliers!: any;
  @tblUpdateSupplierModule.Action('updateDashboardDate')
  private actionUpdateDashboardDate!: any;
  @tblUpdateSupplierModule.Getter('getDashboardSupplieDate')
  private dashboardSupplieDate!: any;
  @tblUpdateSupplierModule.Action('getCompanyWrGruppen')
  private actionGetCompanyWrGruppen!: any;

  @tblUpdateConditionModule.Action('updateDashboardDate')
  private actionUpdateConditionDashboardDate!: any;
  @tblUpdateDocumentModule.Action('updateDashboardDate')
  private actionUpdateDocumentDashboardDate!: any;
  @tblUpdateDocumentModule.Getter('getDashboardDate')
  private dashboardDocumentDate!: any;
  @tblUpdateSupplierModule.Getter('getTblUpdateSuppliers')
  private tblUpdateSuppliers!: OdataItems<TblUpdateSupplier>;
  @tblUpdateSupplierModule.Getter('getTblUpdateSuppliersSearchParams')
  private tblUpdateSuppliersSearchParams!: SearchParams;
  @tblUpdateConditionModule.Action('updateDashboarCompanyWrGruppen')
  private actionUpdateDashboarCompanyWrGruppen!: any;
  @tblUpdateSupplierModule.Action('updateDashboarCompanyWrGruppen')
  private actionUpdateDashboarCompanyWrGruppenSp!: any;

  @tblUpdateConditionModule.Action('getTblUpdateConditions')
  private actionGettblUpdateConditions!: any;
  @tblUpdateConditionModule.Getter('getTblUpdateConditions')
  private tblUpdateConditions!: OdataItems<TblUpdateCondition>;
  @tblUpdateConditionModule.Getter('getTblUpdateConditionsSearchParams')
  private tblUpdateConditionsSearchParams!: SearchParams;

  @tblUpdateDocumentModule.Action('getTblUpdateDocuments')
  private actionGettblUpdateDocuments!: any;
  @tblUpdateDocumentModule.Getter('getTblUpdateDocuments')
  private tblUpdateDocuments!: OdataItems<TblUpdateDocument>;
  @tblUpdateDocumentModule.Getter('getTblUpdateDocumentsSearchParams')
  private tblUpdateDocumentsSearchParams!: SearchParams;

  @tblUpdateDocumentModule.Action('getCountWerbungDocument')
  private actionGetCountWerbungDocument!: any;
  @tblUpdateDocumentModule.Getter('getTblUpdateDocumentrsWerbungTotal')
  private getWerbungDocumentCount!: any;

  @tblUpdateConditionModule.Action('updateDashboardConditionsStatus')
  private actionGetDashboardConditionsStatus!: any;
  @tblUpdateConditionModule.Getter('getDashboadrdConditionsStatus')
  private dashboardConditionsStatus!: any;
  @tblUpdateDocumentModule.Action('updateDashboardDocumentsStatus')
  private actionGetDashboardDocumentsStatus!: any;
  @tblUpdateDocumentModule.Getter('getDashboadrdDocumentsStatus')
  private dashboardDocumentsStatus!: any;
  @tblUpdateSupplierModule.Action('updateDashboardSuppliersStatus')
  private actionGetDashboardSuppliersStatus!: any;
  @tblUpdateSupplierModule.Getter('getDashboadrSupplierStatus')
  private dashboardSuppliersStatus!: any;

  @tblUpdateConditionModule.Action('getConditionsVisible')
  private actionGetConditionsVisible!: any;
  @tblUpdateConditionModule.Getter('getConditionsVisible')
  private getConditionsVisible!: boolean;

  @authModule.Getter('azureUser')
  private azureUser!: any;
  @authModule.Getter('azureUserEmail')
  private azureEmail!: any;

  // get azureEmail() {
  //   console.log('azureUserEmail :>> ', this.azureUserEmail);
  //   let emailFromUrl = GeneralUtils.getObjProperty(this.$route.query, CONST.emailUrlParam);
  //   console.log('azureEmailazureEmailemail :>> ', email);
  //   return this.azureUser?.idTokenClaims?.preferred_username || emailFromUrl;
  // }

  @tblUpdateConditionModule.Getter('getDashboardDate')
  private dashboardDate!: any;
  @tblUpdateConditionModule.Getter('getDashboarCompanyWrGruppen')
  private dashboardCompanyWrGruppen!: any;

  companyWrGruppen: CompanyWrGruppenResult[] = [];
  private companiesWrGruppen: string[] = [];
  private selectedStatus: string = '';

  dateSearch: any = '';
  changeDate(value: any) {
    console.log(
      '!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!************************************************************************************************************* change Date***************  ===>>> value  ',
      value
    );
    // prevent strange 2 request with the same value
    if (this.dateSearch == value) {
      return;
    }

    this.dateSearch = value || undefined; // new Date(value).toJSON();
    // this.triggerSearchDataUpdate();
    this.actionGettblUpdateSuppliers({
      searchParams: this.tblUpdateSuppliersSearchParams,
      supplierSearchData: {
        datum: this.dateSearch,
        companiesWrGruppen: this.companiesWrGruppen,
        contactEmail: this.azureEmail,
        statusSupplier: this.selectedStatus,
      },
    });

    this.actionGettblUpdateConditions({
      searchParams: this.tblUpdateConditionsSearchParams,
      conditionSearchData: {
        datum: this.dateSearch,
        companiesWrGruppen: this.companiesWrGruppen,
        contactEmail: this.azureEmail,
        statusCondition: this.selectedStatus,
      },
    });
    console.log('***********************changeDate****get TblUpdateDocuments***************  ===>>>   ');
    this.actionGettblUpdateDocuments({
      searchParams: this.tblUpdateDocumentsSearchParams,
      documentSearchData: {
        datum: this.dateSearch,
        contactEmail: this.azureEmail,
        statusDocument: this.selectedStatus,
      },
    });

    this.actionGetCountWerbungDocument({
      searchParams: this.tblUpdateDocumentsSearchParams,
      documentSearchData: {
        datum: this.dateSearch,
        documentType: 'werbung',
        contactEmail: this.azureEmail,
        statusDocument: this.selectedStatus,
      },
    });

    this.actionUpdateDashboardDate(value);
    this.actionUpdateConditionDashboardDate(value);
    this.actionUpdateDocumentDashboardDate(value);
  }

  get suppliersCount() {
    console.log('***********************suppliers Count***************  ===>>>   ');
    return this.tblUpdateSuppliers.total;
  }
  get conditionsCount() {
    //return 5;
    console.log('***********************conditions Count****get TblUpdateConditions***************  ===>>>   ');
    return this.tblUpdateConditions.total;
  }

  get documentsCount() {
    //return 5;
    console.log('***********************conditions Count****get TblUpdateDocuments***************  ===>>>   ');
    return this.tblUpdateDocuments.total;
  }

  get documentsWerbungCount() {
    //return 5;
    console.log(
      '*********************** Count****get CountWerbungDocument***************  ===>>> this.get CountWerbungDocument  ',
      this.getWerbungDocumentCount
    );
    return this.getWerbungDocumentCount;
  }

  async mounted() {
    console.log(
      '-------------------------------------------------------------------------------------------------------------------------------------***********************moun ted***************  ===>>> this.dashboardDate  ',
      this.getConditionsVisible,
      '  dashboardDate   ',
      this.dashboardDate,
      '  this.dashboardSupplieDate ',
      this.dashboardSupplieDate
    );
    this.selectedStatus = 'open';
    if (this.dashboardDate) {
      this.dateSearch = this.dashboardDate;
      this.actionUpdateDashboardDate(this.dateSearch);
      this.actionUpdateDocumentDashboardDate(this.dateSearch);
    } else if (this.dashboardSupplieDate) {
      this.dateSearch = this.dashboardSupplieDate;
      this.actionUpdateConditionDashboardDate(this.dateSearch);
      this.actionUpdateDashboardDate(this.dateSearch);
    } else if (this.dashboardDocumentDate) {
      this.dateSearch = this.dashboardDocumentDate;
      this.actionUpdateConditionDashboardDate(this.dateSearch);
      this.actionUpdateDashboardDate(this.dateSearch);
    } else {
      this.dateSearch = this.getPeriodForDashBoard();
      this.actionUpdateDashboardDate(this.dateSearch);
      this.actionUpdateConditionDashboardDate(this.dateSearch);
      this.actionUpdateDocumentDashboardDate(this.dateSearch);
    }

    this.actionGetDashboardSuppliersStatus('open');
    this.actionGetDashboardConditionsStatus('open');
    this.actionGetDashboardDocumentsStatus('open');
    console.log('----------------------------this.azureEmail :>> ', this.azureEmail);
    this.actionGetConditionsVisible(this.azureEmail);
    var promiseAll = [
      this.getTblUpdateSuppliers(),
      this.getTblUpdateConditions(),
      this.getTblUpdateDocuments(),
      this.getTblUpdateWerbungDocuments(),
      this.getCompanyZrsFromBackend(),
    ];

    await Promise.all(promiseAll);

    this.actionUpdateDashboarCompanyWrGruppen();
    this.actionUpdateDashboarCompanyWrGruppenSp();
  }

  private async getTblUpdateSuppliers() {
    console.log(
      '***********************get Tbl UpdateSuppliers***************  ===>>>  this.tblUpdateSuppliersSearchParams ',
      this.tblUpdateSuppliersSearchParams
    );
    await this.actionGettblUpdateSuppliers({
      searchParams: this.tblUpdateSuppliersSearchParams,
      supplierSearchData: {
        datum: this.dateSearch,
        companiesWrGruppen: this.companiesWrGruppen,
        contactEmail: this.azureEmail,
        statusSupplier: this.selectedStatus,
      },
    });
  }

  private async getTblUpdateConditions() {
    console.log(
      '***************************get TblUpdate Conditions***************  ===>>> this.tblUpdateConditionsSearchParams  ',
      this.tblUpdateConditionsSearchParams
    );
    await this.actionGettblUpdateConditions({
      searchParams: this.tblUpdateConditionsSearchParams,
      conditionSearchData: {
        datum: this.dateSearch,
        companiesWrGruppen: this.companiesWrGruppen,
        contactEmail: this.azureEmail,
        statusCondition: this.selectedStatus,
      },
    });
  }

  private async getTblUpdateDocuments() {
    console.log(
      '***************************get TblUpdate Conditions***************  ===>>> this.tblUpdateConditionsSearchParams  ',
      this.tblUpdateConditionsSearchParams
    );
    await this.actionGettblUpdateDocuments({
      searchParams: this.tblUpdateDocumentsSearchParams,
      documentSearchData: {
        datum: this.dateSearch,
        contactEmail: this.azureEmail,
        statusDocument: this.selectedStatus,
      },
    });
  }

  private async getTblUpdateWerbungDocuments() {
    console.log(
      '***************************get TblUpdate Conditions***************  ===>>> this.tblUpdateConditionsSearchParams  ',
      this.tblUpdateConditionsSearchParams
    );
    await this.actionGetCountWerbungDocument({
      searchParams: this.tblUpdateDocumentsSearchParams,
      documentSearchData: {
        datum: this.dateSearch,
        documentType: 'werbung',
        contactEmail: this.azureEmail,
        statusDocument: this.selectedStatus,
      },
    });
  }

  private async getCompanyZrsFromBackend() {
    // const payload = {
    //   isInSuperAdminOrSupportGroup: false,
    //   contactType: contactType,
    //   IsActive: this.searchData.isActive,
    // };
    await this.actionGetCompanyWrGruppen({ searchData: { contactEmail: this.azureEmail } })
      .then((result: CompanyWrGruppenResult[]) => {
        console.log(
          '----------------------------------------------------------get  CompanyZrsFromBackend-------------------------------------------  ===>>> result  ',
          result
        );
        this.companyWrGruppen = result;
      })
      .catch((err: any) => {});
  }

  // TODO: (EGRUP-84) Move this method in global space (probably try to mixin/helper or something like that )
  get isRealXl() {
    return this.$vuetify.breakpoint.width < 1920;
  }

  private openSuppliers() {
    console.log('-------------------open open Suppliers*********************  ===>>>   ');
    this.$router.push({
      name: ROUTES.tasks_suppliers,
    });
  }

  private openConditios() {
    console.log('-------------------open Conditios*********************  ===>>>   ');
    this.$router.push({
      name: ROUTES.tasks_conditions,
    });
  }

  private openDocuments() {
    console.log(
      '-------------------open open Documents*********************  ===>>> this.$route.fullPath  ',
      this.$route.fullPath
    );
    this.$router.push({
      name: ROUTES.tasks_documents,
    });
  }

  private openWerbung() {
    console.log(
      '-------------------open open Werbung*********************  ===>>>  this.$route.fullPath ',
      this.$route.fullPath
    );
    this.$router.push({
      name: ROUTES.werbung,
    });
  }

  changeCompanyWrGruppen() {
    this.actionUpdateDashboarCompanyWrGruppen(this.companiesWrGruppen);
    this.actionUpdateDashboarCompanyWrGruppenSp(this.companiesWrGruppen);
    this.getTblUpdateSuppliers();
    this.getTblUpdateConditions();
  }

  getPeriodForDashBoard(): string {
    let dateStart = new Date();
    let dateStartDay = dateStart.getDay();
    let dateEnd = new Date();
    dateEnd.setDate(dateEnd.getDate() + (7 - dateStartDay));
    let startDate = new Date();
    startDate.setDate(dateEnd.getDate() - 13);
    console.log(
      '------------------------------------------------------------------------------------------------------***********************moun ted***************  ===>>>  dateEnd',
      dateEnd,
      '    startDate     ',
      startDate,
      ' dateStart  ',
      dateStart,
      '   dateStartDay  ',
      dateStartDay
    );
    const periodForDashBoard =
      startDate.getFullYear() +
      '-' +
      ('0' + (startDate.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + startDate.getDate()).slice(-2) +
      ',' +
      dateEnd.getFullYear() +
      '-' +
      ('0' + (dateEnd.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + dateEnd.getDate()).slice(-2);
    console.log(
      '------------------------------------------------------------------------------------------------------***********************moun ted***************  startDate ',
      startDate.getDate(),
      ' getMonth  ',
      startDate.getMonth(),
      ' getFullYear  ',
      startDate.getFullYear(),
      '  ----------------------------resultDate --------  ',
      periodForDashBoard
    );
    return periodForDashBoard;
  }

  changeSelectStatus() {
    console.log(
      '------------------------------change change Select Status----------------------------------------------------------------  ===>>>   ',
      this.selectedStatus == null
    );
    this.actionGetDashboardConditionsStatus(this.selectedStatus);
    this.actionGetDashboardDocumentsStatus(this.selectedStatus);
    this.actionGetDashboardSuppliersStatus(this.selectedStatus);
    // console.log('------------------------------change change Select Status----------------------------------------------------------------  ===>>>  dashboard ConditionsStatus ',
    //    this.dashboardConditionsStatus,
    //   '  dashboardDocumentsStatus  ', this.dashboardDocumentsStatus,
    // '   dashboardSuppliersStatus  ', this.dashboardSuppliersStatus);
    this.getTblUpdateSuppliers();
    this.getTblUpdateConditions();
    this.getTblUpdateDocuments();
    this.getTblUpdateWerbungDocuments();
  }

  activeState = [
    {
      text: this.$i18n.t(`select_all`), //'Alle',
      value: 'all',
    },
    {
      text: this.$i18n.t(`select_offen`), //'Offen',
      value: 'open',
    },
    {
      text: this.$i18n.t(`select_erledigt`), //'Erledigt',
      value: 'done',
    },
  ];

  goToNews() {}
  goToKatalog() {}
}
