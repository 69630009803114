import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import gspAttribute, { GspAttribute } from '@/shared/model/gspAttribute';
// import { AUTHORITIES } from '@/shared/store/modules/auth';
import D4yEdit from '@/components/d4y-edit/d4y-edit.vue';
import { CONST } from '@/shared/utils/Constants';
import { ROUTES } from '@/router/routesEnum';
import DateUtils from '@/shared/utils/DateUtils';

const name = 'gspAttribute-edit-dialog';
const logger = new Logger(name);
const gspAttributeManagement = namespace('gspAttribute');

@Component({
  name: name,
  components: {
    D4yEdit,
  },
})
export default class GspAttributeEditDialog extends Vue {
  @gspAttributeManagement.Action('getGspAttribute') private actionGetGspAttribute!: any;
  @gspAttributeManagement.Action('updateGspAttribute')
  private actionUpdateGspAttribute!: any;
  @gspAttributeManagement.Getter('getGspAttributeRoles')
  private roles!: any;

  @Prop()
  attributeId: any;

  async mounted() {
    const gspAttributeId = this.attributeId; //this.$route.params['gspAttributeId'];
    if (gspAttributeId != 0) {
      this.getGspAttribute();
    } else {
      this.gspAttribute = gspAttribute.parse({});
    }
  }
  getGspAttribute() {
    const gspAttributeId = this.attributeId; //this.$route.params['gspAttributeId'];
    this.actionGetGspAttribute(gspAttributeId).then((response: GspAttribute) => {
      this.gspAttribute = Object.assign({}, response);
      this.titleInfo = this.gspAttribute.name;
    });
  }
  private gspAttribute: GspAttribute = gspAttribute.parse({});

  @Ref('observer-add-edit-gspAttribute-form') private observerForm!: any;

  activeTab = 0;
  isLoading = false;
  titleInfo = 'empty';

  get isEmptyGspAttribute() {
    return this.gspAttribute.id == 0;
  }

  get image() {
    return {};
  }

  async onClickedUpdate() {
    this.isLoading = true;
    const result = await this.observerForm.validate();
    if (!result) {
      this.isLoading = false;
      return;
    }
    await this.actionUpdateGspAttribute(this.gspAttribute).then((resp: GspAttribute) => {
      this.isLoading = false;
      // this.getGspAttribute();
    });
  }

  onClickedClose() {
    // this.$router.push({
    //   name: ROUTES.gspAttributes,
    // });
    this.$emit('close-dialog');
  }
}
