import { render, staticRenderFns } from "./tblUpdateDocuments.html?vue&type=template&id=9d8f217e&scoped=true&"
import script from "./tblUpdateDocuments.ts?vue&type=script&lang=ts&"
export * from "./tblUpdateDocuments.ts?vue&type=script&lang=ts&"
import style0 from "./tblUpdateDocuments.scss?vue&type=style&index=0&id=9d8f217e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9d8f217e",
  null
  
)

/* custom blocks */
import block0 from "./tblUpdateDocuments.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2Ftasks%2FtblUpdateDocuments%2FtblUpdateDocuments.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCol,VContainer,VImg,VRow,VTooltip})
