var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-sm-8 px-md-15 px-xl-0",attrs:{"fluid":_vm.isRealXl}},[_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-col',{staticClass:"d-flex justify-start",attrs:{"cols":"12","sm":"11","md":"9","lg":"10","offset-lg":"1"}},[_c('h1',{staticClass:"pr-9 text-h3 font-weight-bold"},[_vm._v(_vm._s(_vm.title))]),_c('d4y-btn',{staticClass:"contacts-filter__btn mt-2 ml-20",attrs:{"large":""},on:{"click":_vm.closeDocuments}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('zur_uebersicht')))])])],1),_c('v-col',{attrs:{"cols":"12","sm":"8","lg":"7","offset-lg":"1"}},[_c('div',{staticClass:"d-flex flex-wrap ml-auto"},[_c('date-field',{staticClass:"pt-2",attrs:{"label":_vm.$t(''),"outlined":false,"locale":_vm.$i18n.locale,"ranged":true},on:{"changeDate":function($event){return _vm.changeDate($event)}},model:{value:(_vm.dateSearch),callback:function ($$v) {_vm.dateSearch=$$v},expression:"dateSearch"}}),_c('d4y-autocomplete',{staticClass:"filter-status-zr__input ml-10",attrs:{"label":_vm.$t('filter_status'),"hide-details":"","name":"companyZr","items":_vm.activeState,"clearable":""},on:{"change":_vm.changeSelectStatus},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}})],1)])],1),_c('v-col',{attrs:{"cols":"12","lg":"10","xl":"10"}},[_c('d4y-table',{attrs:{"isShowTableHeader":false,"haveMoreActions":true,"items":_vm.tblUpdateDocuments.items,"headers":_vm.headers,"image":_vm.image,"serverItemsLength":_vm.tblUpdateDocumentrsTotal,"options":_vm.tblUpdateDocumentsSearchParams.dataOption,"filterFields":_vm.getFilterFields,"isNameAsRef":true},on:{"click:delete":_vm.deleteTblUpdateDocument,"update:options":_vm.tblUpdateDocumentsUpdateOptions},scopedSlots:_vm._u([{key:"doc_name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('span',{staticClass:"blue--text text-decoration-underline cursor_pointer",on:{"click":function($event){$event.stopPropagation();return _vm.openDocuments(item)}}},[_vm._v(_vm._s(item.doc_name))])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('new_tab_title')))])])]}},{key:"createdDate",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"center"},[_vm._v(" "+_vm._s(_vm._f("formatDateTimeWithLocaleAndTimezone")(item.doc_changedate,_vm.$i18n.locale))+" ")])]}},{key:"doneTimeCompleted",fn:function(ref){
var item = ref.item;
return [(item.documentDoneTimeCompleted.length > 1)?_c('span',[_vm._v(_vm._s(_vm._f("formatDateTimeWithLocaleAndTimezone")(item.documentDoneTimeCompleted,_vm.$i18n.locale) +' (' + item.documentUserNameCompleted + ')')+" ")]):_vm._e()]}},{key:"more-actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"ml-2 mr-1 mb-1 mt-1 cursor_pointer",attrs:{"contain":"","height":"24","width":"24","src":item.item.documentDoneTimeCompleted.length == 0 ? require('@/assets/open.png') :  require('@/assets/done.png')},on:{"click":function($event){$event.stopPropagation();return _vm.completedDocument(item.item)}}},'v-img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.item.documentUserNameCompleted.length == 0 ? _vm.$t('btn_completed') : _vm.$t('btn_processed')))])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }