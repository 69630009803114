import { render, staticRenderFns } from "./gsp-role-data-dialog.html?vue&type=template&id=1d696e31&scoped=true&"
import script from "./gsp-role-data-dialog?vue&type=script&lang=ts&"
export * from "./gsp-role-data-dialog?vue&type=script&lang=ts&"
import style0 from "./gsp-role-data-dialog.scss?vue&type=style&index=0&id=1d696e31&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d696e31",
  null
  
)

/* custom blocks */
import block0 from "./gsp-role-data-dialog.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2Froles-services%2FgspRole%2Fgsp-role-data-dialog%2Fgsp-role-data-dialog.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VCard,VCardActions,VCardText,VCol,VDialog,VForm,VIcon,VRow,VSpacer,VToolbar,VToolbarTitle})
