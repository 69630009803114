import { render, staticRenderFns } from "./login.html?vue&type=template&id=6bd2d878&scoped=true&"
import script from "./login.ts?vue&type=script&lang=ts&"
export * from "./login.ts?vue&type=script&lang=ts&"
import style0 from "./login.scss?vue&type=style&index=0&id=6bd2d878&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bd2d878",
  null
  
)

/* custom blocks */
import block0 from "./login.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2Fhome%2Flogin%2Flogin.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports