import { Component, Ref, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import axios from 'axios';
import { Logger } from 'fsts';
import { ROUTES } from '@/router/routesEnum';
import test from './script';

const logger = new Logger('vme-iframe-test');

// should have method on backen since here if send request to `api.vme...` then get CORS error and in `no-cors` mode always get 401 error see (https://stackoverflow.com/questions/57615599/fetch-endpoint-with-basic-auth-gives-401)

const authModule = namespace('auth');

@Component({
  components: {},
})
export default class VmeIframeTest extends Vue {
  @authModule.Action('getVmeSupplierData')
  private actionGetVmeSupplierData!: any;
  @authModule.Action('getVmeSupplierUmsatz')
  private actionGetVmeSupplierUmsatz!: any;
  @authModule.Action('getVmeApiUserId')
  private actionGetVmeApiUserId!: any;
  @authModule.Getter('azureUser')
  private azureUser!: any;

  @Ref('vme-supplier-markup')
  private vmeSupplierRef!: any;
  @Ref('vme-supplier-markup22')
  private refIframe!: any;

  lieferantId = '';
  vmeApiUserId = '';
  vmeApiUserList: any = [];
  vmeApiUserZrNummer = '';

  mainUrlPart = 'https://api.vme-einrichten.de';
  vmeUrlPart = '';
  initUrl = '';
  isEmailError = false;
  isEmailRequestWasSent = false;

  apiVmeHistory: Array<string> = [];
  linkNavCount = 0;
  clearSearchInput() {}

  get isRealXl() {
    return this.$vuetify.breakpoint.width < 1920;
  }

  get urlName() {
    return this.$route.name?.toLowerCase();
  }
  get isUmsatzRoute() {
    return this.urlName?.includes(ROUTES.vmeUmsatz.toLowerCase());
  }

  get multipleZrNummer() {
    return this.vmeApiUserList.length > 1;
  }

  markup: any = '';
  markup2: any = '';





  changeZrNummer(val: any) {
    if (val?.newintranet) {
      this.vmeApiUserId = val.newintranet;
      this.loadVmeData();
    }
  }

  loadIdByEmail() {
    const email = this.$route.query?.email;
    this.actionGetVmeApiUserId(email)
      .then((result: any) => {
        console.log('actionGetVmeApiUserId result :>> ', result);
        if (result.data && result.data[0]) {
          this.vmeApiUserList = result.data;
          this.vmeApiUserId = result.data[0]?.newintranet;
          this.vmeApiUserZrNummer = result.data[0]?.wwwuser_login;
        }

        this.isEmailRequestWasSent = true;
      })
      // .then(() => {
      //   this.loadVmeData()
      // })
      .catch((err: any) => {
        this.isEmailError = true;
        logger.error(err);
      });

    return new Promise((resolve, reject) => {
      // (*)
      setTimeout(() => resolve(this.vmeApiUserId), 1000);
    });
  }

  private setVmeUrlPart() {
    console.log('this.$route :>> ', this.$route.name);
    let urlName = this.$route.name;
    switch (urlName) {
      // vmeKollege?email=chaslau.kedas@einrichtungspartnerring.com
      case ROUTES.vmeKollege: // plz - 42119
        this.vmeUrlPart = 'kollegenauslieferung';
        break;
      case ROUTES.vmeBlock:
        this.vmeUrlPart = 'blockverrechnung';
        break;
      case ROUTES.vmeUmsatz:
        this.vmeUrlPart = 'umsatzvergleich';
        break;
      case ROUTES.vmeUmsatzanzeige:
        this.vmeUrlPart = 'umsatzanzeige';
        break;
      // return the same data as `vmeUmsatz`
      case ROUTES.vmeUmsatzvergleich:
        this.vmeUrlPart = 'umsatzvergleich'; // ERROR `not found` for `vmeUmsatzvergleich` so use `umsatzvergleich`
        // this.vmeUrlPart = 'vmeUmsatzvergleich';
        break;

      default:
        break;
    }
  }

  removeTrailingSlash(str: string) {
    return str.endsWith('/') ? str.slice(0, -1) : str;
  }

  async created() {
    this.setVmeUrlPart();
    console.log('this.$route :>> ', this.$route);
    console.log('this.$route.query EMAIL :>> ', this.$route.query?.email);
    this.loadIdByEmail().then(() => {
      this.loadVmeData();
    });
    // var promiseAll = [this.loadIdByEmail(), this.loadVmeData()]; // not working properly
    // await Promise.all(promiseAll);

    const header = document.querySelector('header');
    header?.remove();
    const leftMenu = document.querySelector('.left-menu.v-navigation-drawer');
    leftMenu?.setAttribute('style', 'display: none');
    const app: any = document.querySelector('.v-application--wrap');
    app.style.setProperty('background', 'white'); //  = 'white !important';
  }

  mounted() {
    console.log('mounted mounted this.urlName :>> ', this.urlName);
    if (this.isUmsatzRoute) {
      console.log('isUmsatzRoute', this.isUmsatzRoute);
      let recaptchaScript = document.createElement('script');
      recaptchaScript.type = 'text/javascript';
      // let code = `${test.checkPara.toString()} ${test.NumerischCheck.toString()}`;
      let code = umsatzScript();
      //  console.log('mounted code :>> ', code);
      recaptchaScript.appendChild(document.createTextNode(code));
      document.head.appendChild(recaptchaScript);
    }

    // console.log('window :>> ', window);
    this.vmeSupplierRef.onclick = (e: any) => {
      e.preventDefault();

      console.log('click on :>> ', e);
      console.log('click on  e.target:>> ', e.target);
      if (e.target.tagName.toLowerCase() == 'a' && !!e.target?.href) {
        console.log('href :>> ', e.target.href);
        const correctHref = new URL(e.target.href);

        // let mainUrlPart = 'https://api.vme-einrichten.de';
        // change link for files according to link from `iframe`
        // if (e.target.href.includes('download.php')) {
        //   mainUrlPart = `https://api.vme-einrichten.de/request/${this.vmeApiUserId}/kondition`;
        // }

        const targetUrl = `${this.mainUrlPart}${correctHref.pathname + correctHref.search}`;
        this.actionGetVmeSupplierData(targetUrl)
          .then((result: any) => {
            console.log('result :>> ', result);
            this.apiVmeHistory.push(targetUrl);
            this.linkNavCount++;
            // (AD-80) need to replace relative URL to work in `iframe` with `srcdoc` attribute
            const resultWithAbsoluteUrl = result.data.replaceAll('onclick=history.back()', '');
            this.markup = resultWithAbsoluteUrl;
          })
          .catch((err: any) => {});
      }

      if (e.target.value?.includes('Zurück')) {
        if (this.linkNavCount == 0) {
          console.log('no history navigation (1st page) :>> ');
          return;
        }
        const targetUrl = this.apiVmeHistory[this.linkNavCount - 1];
        this.actionGetVmeSupplierData(targetUrl)
          .then((result: any) => {
            console.log('result :>> ', result);
            this.apiVmeHistory.pop();
            this.linkNavCount--;
            // (AD-80) need to replace relative URL to work in `iframe` with `srcdoc` attribute
            const resultWithAbsoluteUrl = result.data.replaceAll('onclick=history.back()', ''); //.replaceAll('href="/','v-on:click.prevent="showResponder(responder)" href="https://api.vme-einrichten.de/')
            this.markup = resultWithAbsoluteUrl;
          })
          .catch((err: any) => {});
      }

      if (e.target.tagName.toLowerCase() == 'img' && e.target.parentNode.tagName.toLowerCase() == 'a') {
        console.log('img with link :>> ');
        const correctHref = new URL(e.target.parentNode.href);

        const targetUrl = `${this.mainUrlPart}${correctHref.pathname + correctHref.search}`;
        this.actionGetVmeSupplierData(targetUrl)
          .then((result: any) => {
            console.log('result :>> ', result);
            this.apiVmeHistory.push(targetUrl);
            this.linkNavCount++;
            // (AD-80) need to replace relative URL to work in `iframe` with `srcdoc` attribute
            const resultWithAbsoluteUrl = result.data.replaceAll('onclick=history.back()', '');
            this.markup = resultWithAbsoluteUrl;
          })
          .catch((err: any) => {});
      }

      // submitting form
      if (e.target.type == 'submit') {
        console.log('(window as any).isFrameValid :>> ', (window as any).isFrameValid);
        // (AD-93) not allow to submit final `umsatz form` until `isFrameValid` is TRUE
        if (e.target.value.includes('zwischens') && !(window as any).isFrameValid) {
          return;
        }

        const formVme = this.vmeSupplierRef?.querySelector('form');
        const attributes = formVme.attributes;
        let actionUrl = attributes['action']?.value;
        actionUrl = this.removeTrailingSlash(actionUrl);
        console.log('actionUrl :>> ', actionUrl);

        let formData = new FormData(formVme);
        var queryParts = [];
        var entries = formData.entries();
        for (let pair of entries) {
          // console.log('pair :>> ', pair);
          // pairs like `['x_land', 'DE']` or `['sid', '1703676816.0571']`
          queryParts.push(encodeURIComponent(pair[0]) + '=' + encodeURIComponent(pair[1].toString()));
        }
        var query = queryParts.join('&');

        let targetUrl = `${this.mainUrlPart}${actionUrl}?${query}`;
        // 2024-01-29 Now `actionUrl` contains `api.vme-einrichten.de` domain but before this date it did NOT
        if (actionUrl.toString().includes('api.vme-einrichten.de')) {
          targetUrl = `${actionUrl}?${query}`;
        }

        // (AD-93) query params for `umsatz` return error
        if (this.isUmsatzRoute) {
          targetUrl = `${actionUrl}`;

          let payload: any = {
            uri: targetUrl,
          };
          const formDataUmsatz = this.prepareFormData();
          payload.formData = formDataUmsatz;

          this.actionGetVmeSupplierUmsatz(payload)
            .then((result: any) => {
              console.log('result :>> ', result);
              this.apiVmeHistory.push(targetUrl);
              this.linkNavCount++;
              // (AD-80) need to replace relative URL to work in `iframe` with `srcdoc` attribute
              let resultWithAbsoluteUrl = result.data.replaceAll('onclick=history.back()', ''); //.replaceAll('href="/','v-on:click.prevent="showResponder(responder)" href="https://api.vme-einrichten.de/')
              //  NOT helping
              // if (resultWithAbsoluteUrl.includes('UmsVergl')) {
              //   resultWithAbsoluteUrl = resultWithAbsoluteUrl.replaceAll('<input ', '<input form="UmsVergl" ');
              // }
              this.markup = resultWithAbsoluteUrl;
            })
            .catch((err: any) => {});
        } else {
          this.actionGetVmeSupplierData(targetUrl)
            .then((result: any) => {
              console.log('result :>> ', result);
              this.apiVmeHistory.push(targetUrl);
              this.linkNavCount++;
              // (AD-80) need to replace relative URL to work in `iframe` with `srcdoc` attribute
              let resultWithAbsoluteUrl = result.data.replaceAll('onclick=history.back()', ''); //.replaceAll('href="/','v-on:click.prevent="showResponder(responder)" href="https://api.vme-einrichten.de/')
              //  NOT helping
              // if (resultWithAbsoluteUrl.includes('UmsVergl')) {
              //   resultWithAbsoluteUrl = resultWithAbsoluteUrl.replaceAll('<input ', '<input form="UmsVergl" ');
              // }
              this.markup = resultWithAbsoluteUrl;
            })
            .catch((err: any) => {});
        }
      }
    };

    //  console.log('this.refIframe.contentWindow.onclick :>> ', this.refIframe.contentWindow.onclick);
  }

  prepareFormData() {
    let result: Array<string> = [];
    const inputs = document.querySelectorAll('input[type="text"]');
    inputs.forEach((el: any) => {
      result.push(`${el.name};;;${el.value}`);
    });
    const selects = document.querySelectorAll('select');
    selects.forEach((el: any) => {
      result.push(`${el.name};;;${el.value}`);
    });
    return result;
  }

  loadVmeData() {
    const initUrl = `https://api.vme-einrichten.de/request/${this.vmeApiUserId}/${this.vmeUrlPart}`;
    this.initUrl = initUrl;

    this.actionGetVmeSupplierData(initUrl)
      .then((result: any) => {
        // console.log('result actionGetVmeSupplierData :>> ', result.data);
        this.apiVmeHistory.push(initUrl);
        // (AD-80) need to replace relative URL to work in `iframe` with `srcdoc` attribute
        const resultWithAbsoluteUrl = result.data.replaceAll('onclick=history.back()', '');
        // .replaceAll('href=/', 'href=https://api.vme-einrichten.de/')
        // .replaceAll('href="/', 'href="https://api.vme-einrichten.de/')
        // .replaceAll('src=/', 'href=https://api.vme-einrichten.de/');
        this.markup = resultWithAbsoluteUrl;
        // console.log('this.vmeSupplierRef from change VME 222 :>> ', this.vmeSupplierRef);

        // this.showResponder(); // TODO: try to implement ignore and delete iframe approach (now cannot catch iframe click event)
        // console.log('this.$refs :>> ', this.$refs);
      })
      .catch((err: any) => {});
  }

  showResponder() {
    // console.log('this.refIframe :>> ', this.refIframe);

    this.refIframe.addEventListener(
      'click',
      function (event: any) {
        console.log('eevnet :>> ', event);
      },
      false
    );
    window[0].document.onclick = (e: any) => {
      e.preventDefault();
      console.log('cleick on :>> ', e);
      if (e.target.tagName.toLowerCase() == 'a') {
        console.log('href :>> ', e.target.href);
        // const correctHref = new URL(e.target.href);
        // const targetUrl = `https://api.vme-einrichten.de${correctHref.pathname}`;
        // this.actionGetVmeSupplierData(targetUrl).then((result:any) => {
        //   console.log('result :>> ', result);
        //   // (AD-80) need to replace relative URL to work in `iframe` with `srcdoc` attribute
        //   const resultWithAbsoluteUrl = result.data;//.replaceAll('href="/','v-on:click.prevent="showResponder(responder)" href="https://api.vme-einrichten.de/')
        //   this.markup = resultWithAbsoluteUrl;
        //  }).catch((err:any) => {

        // });
      }
    };
    console.log('window :>> ', window);
    const xx = (document.getElementById('myiframe') as any).contentWindow!.document;
    console.log('xx :>> ', xx);

    xx.addEventListener(
      'click',
      function (event: any) {
        console.log('eevnet :>> ', event);
      },
      false
    );

    this.refIframe.contentWindow.onclick = (e: any) => {
      e.preventDefault();
      console.log('cleick on :>> ', e);
      if (e.target.tagName.toLowerCase() == 'a') {
        console.log('href :>> ', e.target.href);
        // const correctHref = new URL(e.target.href);
        // const targetUrl = `https://api.vme-einrichten.de${correctHref.pathname}`;
        // this.actionGetVmeSupplierData(targetUrl).then((result:any) => {
        //   console.log('result :>> ', result);
        //   // (AD-80) need to replace relative URL to work in `iframe` with `srcdoc` attribute
        //   const resultWithAbsoluteUrl = result.data;//.replaceAll('href="/','v-on:click.prevent="showResponder(responder)" href="https://api.vme-einrichten.de/')
        //   this.markup = resultWithAbsoluteUrl;
        //  }).catch((err:any) => {

        // });
      }
    };
  }
}

// Example of markup returned from https://api.vme-einrichten.de/request/26dd2ece3feb8ec00dddec3d351350f9/konditionen
/*`
<script language="JavaScript" type="text/javascript">
<!--
function toggleClassVisibility(cls)
{
   var elems = document.getElementsByClassName(cls);
   var i = 0;
   for (i = 0; i < elems.length; i++)
   {
       if (elems[i].style.display == "none")
       {
          elems[i].style.display = "";
       }
       else
       {
          elems[i].style.display = "none";
       }
   }
}



function magnify(imagefile, horizontal, vertical)
{
   var ratio = horizontal / vertical;

   var new_horizontal = horizontal;
   var new_vertical   = vertical;

   if (horizontal > 1000)
   {
      new_horizontal = 1000;
      new_vertical   = new_horizontal / ratio;
   }


   var wincontent = imagefile;
  // wincontent = new_horizontal+" "+new_vertical;
  // wincontent = "<img width=100% src="+imagefile+">";

   var winlogicalname = "detailimage";

   var winparams      = "dependent=yes,locationbar=no,menubar=yes,resizable=no,scrollbars=yes,screenX=50,screenY=50,width=" + new_horizontal + ",height=" + new_vertical;

  // -- so geht es...
  // detailWindow = window.open(wincontent, winlogicalname, winparams);
  // detailWindow.document.write("<img src=\'"+imagefile+"\' width=100%>");

  // -- so geht es auch...
  // detailWindow = window.open("", winlogicalname, winparams);
  // detailWindow.document.all.inhalt.innerHTML = "<img src=\'"+imagefile+"\' width=100%>";

  // -- aber besser so...
   detailWindow = window.open("", winlogicalname, winparams);
   detailWindow.document.write("<div id=inhalt></div>");
   var inhalt = detailWindow.document.getElementById("inhalt");
   inhalt.innerHTML = "<img src=\'"+imagefile+"\' width=100%>";
  // <<

   detailWindow.creator = self;

   detailWindow.focus();
}


function openWindow(url, name, w, h)
{
   var breite = 380;
   var hoehe  = 380;

   if (w > 0)   breite = w;
   if (h > 0)   hoehe  = h;

   winparams = "menubar=yes,scrollbars,resizable,width=" + breite + ",height="+hoehe+",screenX=50,screenY=50";
   detailWindow = window.open(url, name, winparams);
   detailWindow.focus();
}

function openNakedWindow(url, name, w, h)
{
   var breite = 380;
   var hoehe  = 380;

   if (w > 0)   breite = w;
   if (h > 0)   hoehe  = h;

   winparams = "menubar=no,navigationbar=no,scrollbars,resizable,width=" + breite + ",height="+hoehe+",screenX=50,screenY=50";
   detailWindow = window.open(url, name, winparams);
   detailWindow.focus();
}

function slideShow(url, name)
{
   var breite = 850;
   var hoehe  = 650;

   winparams = "menubar=yes,scrollbars,resizable,width=" + breite + ",height=" + hoehe + ",screenX=30,screenY=30";
   detailWindow = window.open(url, name, winparams);
   detailWindow.focus();
}

//
-->
</script>


<!-- Google Tag Manager -->
<script>
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-TNKVSQ3');
</script>
<!-- End Google Tag Manager -->


<HTML>

<HEAD>
<TITLE>Konditionsliste f&uuml;r 825204
</TITLE>
<meta http-equiv="Content-Type" content="text/html; charset=iso-8859-1">
<link rel="stylesheet" type="text/css" href="/STYLE.CSS">
</HEAD>

<BODY id="wholepage" bgcolor="#FFFFFF" TEXT="#000000" LINK="#215DC6" VLINK="#4400AA" ALINK="#669966">
<TABLE class="rounded bordered" cellspacing=0 cellpadding=2 width=100%>
  <TR>
    <TD bgcolor=#E6E6E6 valign=bottom>&nbsp;Konditionsliste f&uuml;r 825204 (12.12.2023)</TD>
    <TD bgcolor=#E6E6E6 valign=top align=right>
      480009&nbsp&nbsp&nbsp<input type=button class="bordered rounded" style='line-height: 25px; background: #ee7f00; color: white; position: relative; top: -4px;' onclick=history.back() value="&lt;&lt;&nbsp;Zur&uuml;ck"> &nbsp;<img style='position: relative; top: 4px; right: 0;' class="bordered rounded" onclick="window.print();" src=/clipart/printer.png width=24px alt="Drucken">&nbsp;&nbsp;&nbsp;    </TD>
  </TR>
</TABLE>
<br><br><b>Adresse</b><br>
<table class="rounded bordered" cellspacing=0 cellpadding=2 width=100%>
  <TR>
    <th bgcolor=#E6E6E6 align=left>ZR-Nr</th>
    <th bgcolor=#E6E6E6 align=left>Suchname</th>
    <th bgcolor=#E6E6E6 align=left>Anschrift</th>
    <th bgcolor=#E6E6E6 align=left>Kommunikation</th>
    <th bgcolor=#E6E6E6 align=left>ZR-Status</th>
  <tr>
    <td valign=top>825204</TD>
    <td valign=top>HIMOLLA</td>
    <td valign=top>Himolla Polsterm&ouml;bel GmbH<br>
      <br>Landshuter Str. 38<br><br>D 84416 Taufkirchen/Vils</td>
    <td valign=top>Telefon: 08084 / 2 50<br>Telefax: 0808425558<br>EMail: info@himolla.com<br></td>
    <td valign=top>ZR vom 01.01.1980 bis 31.12.2099<br>(normal)</td>
  </tr>
</table>
<br><b><a href="#start"></a><a name="konditionen">Konditionen</a></b><br>
<tr>
  <table class="rounded bordered" cellspacing=0 cellpadding=2 width=100%>
    <td bgcolor=#E6E6E6 align=left><b>G&uuml;ltig f&uuml;r</b></td>
    <td bgcolor=#E6E6E6 align=left><b>G&uuml;ltig ab</b></td>
    <td bgcolor=#E6E6E6 align=left><b>Stand vom</b></td>
    <td bgcolor=#E6E6E6 align=left><b>Vertrag zur&uuml;ck am</b></td>
    <td bgcolor=#E6E6E6 align=left><b>&Auml;nderung betraf</b></td>
</tr>
<tr>
  <td bgcolor=#FFFFFF valign=top><a
      href="/request/26dd2ece3feb8ec00dddec3d351350f9/kondition/sid=1702386196.7934&domain=vme-einrichten.de&konditionsid=313538091&lieferantnr=825204&aktuell=0&mitglied=825204&user=480009-cke&profil=admin,chef,kond,kondbonus,konddirbonus,logistik,ek,ekfs,vk,zr,st,spot,um,">Warengruppe
      4</td>
  <td bgcolor=#FFFFFF valign=top>01.06.2021 Rechnung</td>
  <td bgcolor=#FFFFFF valign=top>22.03.2021</td>
  <td bgcolor=#FFFFFF valign=top>22.03.2021</td>
  <td bgcolor=#FFFFFF valign=top>s. Skonto&nbsp;</td>
</tr>
<tr>
  <td bgcolor=#FFFFFF valign=top><a
      href="/request/26dd2ece3feb8ec00dddec3d351350f9/kondition/sid=1702386196.7934&domain=vme-einrichten.de&konditionsid=313538030&lieferantnr=825204&aktuell=0&mitglied=825204&user=480009-cke&profil=admin,chef,kond,kondbonus,konddirbonus,logistik,ek,ekfs,vk,zr,st,spot,um,">Warengruppe
      4</td>
  <td bgcolor=#FFFFFF valign=top>08.02.2021 Rechnung</td>
  <td bgcolor=#FFFFFF valign=top>08.02.2021</td>
  <td bgcolor=#FFFFFF valign=top>08.02.2021</td>
  <td bgcolor=#FFFFFF valign=top>s. Werbevereinb. Aktionszeitr. 2021 (An. Logistik)&nbsp;</td>
</tr>
<tr>
  <td bgcolor=#FFFFFF valign=top><a
      href="/request/26dd2ece3feb8ec00dddec3d351350f9/kondition/sid=1702386196.7934&domain=vme-einrichten.de&konditionsid=313538075&lieferantnr=825204&aktuell=0&mitglied=825204&user=480009-cke&profil=admin,chef,kond,kondbonus,konddirbonus,logistik,ek,ekfs,vk,zr,st,spot,um,">Warengruppe
      4</td>
  <td bgcolor=#FFFFFF valign=top>01.01.2021 Rechnung</td>
  <td bgcolor=#FFFFFF valign=top>17.03.2021</td>
  <td bgcolor=#FFFFFF valign=top>18.03.2021</td>
  <td bgcolor=#FFFFFF valign=top>s. Anhang Logistik&nbsp;</td>
</tr>
<tr>
  <td bgcolor=#FFFFFF valign=top><a
      href="/request/26dd2ece3feb8ec00dddec3d351350f9/kondition/sid=1702386196.7934&domain=vme-einrichten.de&konditionsid=313538016&lieferantnr=825204&aktuell=0&mitglied=825204&user=480009-cke&profil=admin,chef,kond,kondbonus,konddirbonus,logistik,ek,ekfs,vk,zr,st,spot,um,">Warengruppe
      4</td>
  <td bgcolor=#FFFFFF valign=top>01.01.2021 Rechnung</td>
  <td bgcolor=#FFFFFF valign=top>20.01.2021</td>
  <td bgcolor=#FFFFFF valign=top>20.01.2021</td>
  <td bgcolor=#FFFFFF valign=top>s. Werbungvereinbarung 2021&nbsp;</td>
</tr>
<tr>
  <td bgcolor=#FFFFFF valign=top><a
      href="/request/26dd2ece3feb8ec00dddec3d351350f9/kondition/sid=1702386196.7934&domain=vme-einrichten.de&konditionsid=313537953&lieferantnr=825204&aktuell=0&mitglied=825204&user=480009-cke&profil=admin,chef,kond,kondbonus,konddirbonus,logistik,ek,ekfs,vk,zr,st,spot,um,">Warengruppe
      4</td>
  <td bgcolor=#FFFFFF valign=top>01.01.2021 Rechnung</td>
  <td bgcolor=#FFFFFF valign=top>21.12.2020</td>
  <td bgcolor=#FFFFFF valign=top>28.12.2020</td>
  <td bgcolor=#FFFFFF valign=top>s. Lieferung, Einzelstaffel, Direktbonus&nbsp;</td>
</tr>
<tr>
  <td bgcolor=#FFFFFF valign=top><a
      href="/request/26dd2ece3feb8ec00dddec3d351350f9/kondition/sid=1702386196.7934&domain=vme-einrichten.de&konditionsid=313537412&lieferantnr=825204&aktuell=0&mitglied=825204&user=480009-cke&profil=admin,chef,kond,kondbonus,konddirbonus,logistik,ek,ekfs,vk,zr,st,spot,um,">Warengruppe
      4</td>
  <td bgcolor=#FFFFFF valign=top>01.01.2020 Rechnung</td>
  <td bgcolor=#FFFFFF valign=top>28.11.2019</td>
  <td bgcolor=#FFFFFF valign=top>28.11.2019</td>
  <td bgcolor=#FFFFFF valign=top>s. Einzelstaffel&nbsp;</td>
</tr>
<tr>
  <td bgcolor=#FFFFFF valign=top><a
      href="/request/26dd2ece3feb8ec00dddec3d351350f9/kondition/sid=1702386196.7934&domain=vme-einrichten.de&konditionsid=313537401&lieferantnr=825204&aktuell=0&mitglied=825204&user=480009-cke&profil=admin,chef,kond,kondbonus,konddirbonus,logistik,ek,ekfs,vk,zr,st,spot,um,">Warengruppe
      4</td>
  <td bgcolor=#FFFFFF valign=top>01.01.2020 Rechnung</td>
  <td bgcolor=#FFFFFF valign=top>05.11.2019</td>
  <td bgcolor=#FFFFFF valign=top>05.11.2019</td>
  <td bgcolor=#FFFFFF valign=top>s. Werbevereinbarung 2020 Änderung&nbsp;</td>
</tr>
<tr>
  <td bgcolor=#FFFFFF valign=top><a
      href="/request/26dd2ece3feb8ec00dddec3d351350f9/kondition/sid=1702386196.7934&domain=vme-einrichten.de&konditionsid=313537396&lieferantnr=825204&aktuell=0&mitglied=825204&user=480009-cke&profil=admin,chef,kond,kondbonus,konddirbonus,logistik,ek,ekfs,vk,zr,st,spot,um,">Warengruppe
      4</td>
  <td bgcolor=#FFFFFF valign=top>01.01.2020 Rechnung</td>
  <td bgcolor=#FFFFFF valign=top>31.10.2019</td>
  <td bgcolor=#FFFFFF valign=top>30.10.2019</td>
  <td bgcolor=#FFFFFF valign=top>s. Werbevereinbarung 2020&nbsp;</td>
</tr>
<tr>
  <td bgcolor=#FFFFFF valign=top><a
      href="/request/26dd2ece3feb8ec00dddec3d351350f9/kondition/sid=1702386196.7934&domain=vme-einrichten.de&konditionsid=313536760&lieferantnr=825204&aktuell=0&mitglied=825204&user=480009-cke&profil=admin,chef,kond,kondbonus,konddirbonus,logistik,ek,ekfs,vk,zr,st,spot,um,">Warengruppe
      4</td>
  <td bgcolor=#FFFFFF valign=top>01.01.2019 Rechnung</td>
  <td bgcolor=#FFFFFF valign=top>17.07.2018</td>
  <td bgcolor=#FFFFFF valign=top>17.07.2018</td>
  <td bgcolor=#FFFFFF valign=top>s. Werbevereinbarung 2019&nbsp;</td>
</tr>
<tr>
  <td bgcolor=#FFFFFF valign=top><a
      href="/request/26dd2ece3feb8ec00dddec3d351350f9/kondition/sid=1702386196.7934&domain=vme-einrichten.de&konditionsid=313536738&lieferantnr=825204&aktuell=0&mitglied=825204&user=480009-cke&profil=admin,chef,kond,kondbonus,konddirbonus,logistik,ek,ekfs,vk,zr,st,spot,um,">Warengruppe
      4</td>
  <td bgcolor=#FFFFFF valign=top>01.04.2018 Rechnung</td>
  <td bgcolor=#FFFFFF valign=top>10.07.2018</td>
  <td bgcolor=#FFFFFF valign=top>10.07.2018</td>
  <td bgcolor=#FFFFFF valign=top>s. Werbevereinbarung 2018, 4x Mal im Jahr statt 3x&nbsp;</td>
</tr>
</table>
<a href=/request/26dd2ece3feb8ec00dddec3d351350f9/ekonditionen/sid=1702386196.7934&domain=vme-einrichten.de&action=KONDITIONSBLATT&vliefnr=825204&bliefnr=825204&jahr=2023&mitglied=480009&user=480009-cke&profil=admin,chef,kond,kondbonus,konddirbonus,logistik,ek,ekfs,vk,zr,st,spot,um,&externalmail=>Aktuelles
  Konditionsblatt drucken</a> <br>
</BODY>

</HTML>
`
*/

// (AD-93) below code not worked in PROD (in separte js/ts) so put in string here
function umsatzScript() {
  let code = `
  function NumerischCheck(betrag) {
    var nipunkt = 0;
    var nikomma = 0;
    var i = 0;
    var nistelle = betrag.length;
  
    for (i = 0; i < betrag.length; i++) {
      if (betrag[i] == '.') {
        nipunkt++;
      }
      if (betrag[i] == ',') {
        nikomma++;
      }
    }
  
    if (nipunkt > 1) {
      return false;
    }
    if (nikomma > 1) {
      return false;
    }
    if (nipunkt > 0 && nikomma > 0) {
      return false;
    }
  
    // ------ Eingabe, z.B. 1.980 prüfen
    for (i = 0; i < betrag.length; i++) {
      if (betrag[i] == '.') {
        nistelle = i;
        break;
      }
    }
  
    if (betrag.length - nistelle > 3) {
      return false;
    }
  
    return true;
  }
  // -------------------------------------------------------------------------------
  
  function checkPara(form) {
    window.isFrameValid = false;
    console.log('checkPara form');
    console.log(form);
    form = document.getElementsByTagName('form')[0];
    console.log(form);
  
    const el = document.createElement('input');
    el.className = 'integr_elements';
    el.placeholder = 'name';
    el.id = 'name_name';
    el.type = 'hidden';
    el.value = 'Olivia';
    form.appendChild(el);
  
    var betrag = 0.0;
    var wert = 0.0;
    var wert2 = 0.0;
  
    // ------ Prüfung Eingabefelder Umsatzvergleich Allgemein
    // Umsatz Gesamt Vorjahr
    betrag = document.querySelector('input[name="GV_UmsatzGesamt"]').value;
    if (isNaN(betrag) == true) {
      alert('Bitte Auswertung Gesamt: Umsatz Gesamt (Vorjahr) numerisch (NNNNNNNN.NN) erfassen !');
      document.querySelector('input[name="GV_UmsatzGesamt"]').focus();
      return false;
    }
  
    // Umsatz Kaufverträge lfd. Jahr
    betrag = document.querySelector('input[name="G_UmsatzKaufvertr"]').value;
    if (isNaN(betrag) == true) {
      alert('Bitte Auswertung Gesamt: Umsatz Kaufverträge numerisch (NNNNNNNN.NN) erfassen !');
      document.querySelector('input[name="G_UmsatzKaufvertr"]').focus();
      return false;
    }
  
    // Umsatz Kaufverträge Vorjahr
    betrag = document.querySelector('input[name="GV_UmsatzKaufvertr"]').value;
    if (isNaN(betrag) == true) {
      alert('Bitte Auswertung Gesamt: Umsatz Kaufverträge numerisch (NNNNNNNN.NN) erfassen !');
      document.querySelector('input[name="GV_UmsatzKaufvertr"]').focus();
      return false;
    }
  
    // Umsatz Gesamt
    betrag = document.querySelector('input[name="G_UmsatzGesamt"]').value;
    if (isNaN(betrag) == true) {
      alert('Bitte Auswertung Gesamt: Umsatz Gesamt numerisch (NNNNNNNN.NN) erfassen !');
      document.querySelector('input[name="G_UmsatzGesamt"]').focus();
      return false;
    }
  
    if (betrag == 0.0) {
      alert('Bitte Auswertung Gesamt: Umsatz Gesamt erfassen!');
      document.querySelector('input[name="G_UmsatzGesamt"]').focus();
      return false;
    }
  
    if (betrag > 0.0) {
      if (NumerischCheck(betrag) == false) {
        alert('Bitte Auswertung Gesamt: Umsatz Gesamt NUMERISCH erfassen!');
        document.querySelector('input[name="G_UmsatzGesamt"]').focus();
        return false;
      }
  
      // VK-Fläche
      wert = document.querySelector('input[name="G_VKFlaeche"]').value;
      if (isNaN(wert) == true) {
        alert('Bitte Auswertung Gesamt: VKFläche numerisch (NNNNN.NN) erfassen !');
        document.querySelector('input[name="G_VKFlaeche"]').focus();
        return false;
      }
  
      if (wert == 0.0) {
        alert('Bitte Auswertung Gesamt: VKFläche erfassen!');
        document.querySelector('input[name="G_VKFlaeche"]').focus();
        return false;
      }
  
      // Gesamt-Fläche
      wert = document.querySelector('input[name="G_GeschFlaeche"]').value;
      if (isNaN(wert) == true) {
        alert('Bitte Auswertung Gesamt: Fläche Gesamt numerisch (NNNNN.NN) erfassen !');
        document.querySelector('input[name="G_GeschFlaeche"]').focus();
        return false;
      }
  
      if (wert == 0.0) {
        alert('Bitte Auswertung Gesamt: Fläche Gesamt erfassen!');
        document.querySelector('input[name="G_GeschFlaeche"]').focus();
        return false;
      }
  
      // Anzahl Verkäufer
      wert = document.querySelector('input[name="G_AnzVerkaeufer"]').value;
      if (isNaN(wert) == true) {
        alert('Bitte Auswertung Gesamt: Anzahl Mitarbeiter Verkauf numerisch (NNNNN.NN) erfassen !');
        document.querySelector('input[name="G_AnzVerkaeufer"]').focus();
        return false;
      }
  
      if (wert == 0.0) {
        alert('Bitte Auswertung Gesamt: Anzahl Mitarbeiter Verkauf erfassen!');
        document.querySelector('input[name="G_AnzVerkaeufer"]').focus();
        return false;
      }
  
      // Anzahl Mitarbeiter
      wert = document.querySelector('input[name="G_AnzMitarbeiter"]').value;
      if (isNaN(wert) == true) {
        alert('Bitte Auswertung Gesamt: Anzahl Mitarbeiter Gesamt numerisch (NNNNN.NN) erfassen !');
        document.querySelector('input[name="G_AnzMitarbeiter"]').focus();
        return false;
      }
  
      if (wert == 0.0) {
        alert('Bitte Auswertung Gesamt: Anzahl Mitarbeiter Gesamt erfassen!');
        document.querySelector('input[name="G_AnzMitarbeiter"]').focus();
        return false;
      }
  
      if (betrag < 10000.0) {
        fehlermeldung = 'Umsatz Gesamt = ' + betrag + '. Ist diese Eingabe korrekt ?';
        alert(fehlermeldung);
      }
    }
    // ------------------------------------------------------
  
    // ------ Küche
    betrag = document.querySelector('input[name="K_UmsatzGesamt"]').value;
    if (betrag > 0.0) {
      if (NumerischCheck(betrag) == false) {
        alert('Bitte Auswertung Küchen: Umsatz Gesamt NUMERISCH erfassen!');
        document.querySelector('input[name="K_UmsatzGesamt"]').focus();
        return false;
      }
  
      if (betrag < 10000.0) {
        fehlermeldung = 'Umsatz Küchen Gesamt = ' + betrag + '. Ist diese Eingabe korrekt ?';
        alert(fehlermeldung);
      }
  
      wert = document.querySelector('input[name="K_VKFlaeche"]').value;
      if (wert == 0.0) {
        alert('Bitte Auswertung Küchen: Fläche Verkauf erfassen!');
        document.querySelector('input[name="K_VKFlaeche"]').focus();
        return false;
      }
  
      wert = document.querySelector('input[name="K_AnzVerkaeufer"]').value;
      if (wert == 0.0) {
        alert('Bitte Auswertung Küchen: Anzahl Mitarbeiter Verkauf erfassen!');
        document.querySelector('input[name="K_AnzVerkaeufer"]').focus();
        return false;
      }
    }
    // ----------------
  
    // ------ Junges Wohnen / Mitnahme
    betrag = document.querySelector('input[name="J_UmsatzGesamt"]').value;
    if (betrag > 0.0) {
      if (NumerischCheck(betrag) == false) {
        alert('Bitte Auswertung Junges Wohnen: Umsatz Gesamt NUMERISCH erfassen!');
        document.querySelector('input[name="J_UmsatzGesamt"]').focus();
        return false;
      }
  
      if (betrag < 10000.0) {
        fehlermeldung = 'Umsatz Junges Wohnen Gesamt = ' + betrag + '. Ist diese Eingabe korrekt ?';
        alert(fehlermeldung);
      }
  
      wert = document.querySelector('input[name="J_AnzVerkaeufer"]').value;
      if (wert == 0.0) {
        alert('Bitte Auswertung Junges Wohnen: Anzahl Mitarbeiter Verkauf erfassen!');
        document.querySelector('input[name="J_AnzVerkaeufer"]').focus();
        return false;
      }
    }
    // ----------------
  
    // ------ Fachsortimente
    betrag = document.querySelector('input[name="F_UmsatzGesamt"]').value;
    if (betrag > 0.0) {
      if (NumerischCheck(betrag) == false) {
        alert('Bitte Auswertung Fachsortimente: Umsatz Gesamt NUMERISCH erfassen!');
        document.querySelector('input[name="F_UmsatzGesamt"]').focus();
        return false;
      }
  
      if (betrag < 10000.0) {
        fehlermeldung = 'Umsatz Fachsortimente Gesamt = ' + betrag + '. Ist diese Eingabe korrekt ?';
        alert(fehlermeldung);
      }
  
      wert = document.querySelector('input[name="F_VKFlaeche"]').value;
      if (wert == 0.0) {
        alert('Bitte Auswertung Fachsortimente: Fläche Verkauf erfassen!');
        document.querySelector('input[name="F_VKFlaeche"]').focus();
        return false;
      }
  
      wert = document.querySelector('input[name="F_AnzVerkaeufer"]').value;
      if (wert == 0.0) {
        alert('Bitte Auswertung Fachsortimente: Anzahl Mitarbeiter Verkauf erfassen!');
        document.querySelector('input[name="F_AnzVerkaeufer"]').focus();
        return false;
      }
    }
    // ----------------
  
    // ------ SB-Möbel
    betrag = document.querySelector('input[name="S_UmsatzGesamt"]').value;
    if (betrag > 0.0) {
      if (NumerischCheck(betrag) == false) {
        alert('Bitte Auswertung SB-Möbel: Umsatz Gesamt NUMERISCH erfassen!');
        document.querySelector('input[name="S_UmsatzGesamt"]').focus();
        return false;
      }
  
      if (betrag < 10000.0) {
        fehlermeldung = 'Umsatz SB-Möbel Gesamt = ' + betrag + '. Ist diese Eingabe korrekt ?';
        alert(fehlermeldung);
      }
    }
    // ----------------
  
    window.isFrameValid = true;
    return true;
  }
`;
  return code.toString();
}
