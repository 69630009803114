import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator';
import UserMini from '@/components/user/mini/user-mini.vue';
import dataOptions, { DataOptions } from '@/shared/model/DataOptions';
import { Logger } from 'fsts';
import DateUtils from '@/shared/utils/DateUtils';
import TreeNavigator from '@/components/tree-navigator/tree-navigator.vue';
import { TranslateResult } from 'vue-i18n';
const logger = new Logger('components.position-list');
const dateUtils = DateUtils;

@Component({ name: 'd4y-edit', components: {} })
export default class D4yEdit extends Vue {
  @Prop({ default: 'white' })
  private color?: string;

  @Prop({
    default: () => {
      return {
        ssrc: '', //img_employee.svg',
        header: '',
        height: 200,
        width: 360,
      };
    },
  })
  private image!: {
    src: string;
    header: string;
    height: number;
    width: number;
  };

  // TODO: (EGRUP-84) Move this method in global space (probably try to mixin/helper or something like that )
  get isRealXl() {
    return this.$vuetify.breakpoint.width < 1920;
  }
}
