import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import gspServices from './../gspServices/gspServices.vue';
import gspModules from './../gspModules/gspModules.vue';
import gspAttributes from './../gspAttributes/gspAttributes.vue';
import gspAddEditDialog from './gsp-add-edit-dialog/gsp-add-edit-dialog.vue';

const logger = new Logger('gsp-dialog');

@Component({
  components: { gspServices, gspModules, gspAttributes,gspAddEditDialog },
  name: 'gsp-dialog',
})
export default class FilterDialog extends Vue {
  @Ref('observer-gsp-dialog-form')
  private observerFilterDialogForm!: any;


  @Prop({ default: false })
  private dialog!: boolean;

  tab:any = null;
  text:any = 'test text';

  isSaving = false;



  clickClose() {
    this.$emit('click:close');
  }

    //#region Gsp-Add-Edit-dialog
    dialogGspAddEdit = {
      show: false,
      model: {},
      typeOfModel: 'service',
      OnClose: () => {
        this.dialogGspAddEdit.show = false;
      },
    };

    showGspAddEditDialog() {
      logger.log('show-gsp-add-edit-dialog');
      this.dialogGspAddEdit.show = true;
    }

    emitId =0;
    openAddEditDialog(payload: any){
      console.log('payload :>> ', payload);
      this.dialogGspAddEdit.show = true;
      this.dialogGspAddEdit.typeOfModel = payload.type;
      this.emitId = payload.id;
    }

    //#endregion
}
