import { ROUTES } from '@/router/routesEnum';
import gspAttribute, { GspAttribute } from '@/shared/model/gspAttribute';
import { OdataItems } from '@/shared/model/OdataItems';
import { SearchParams } from '@/shared/model/searchParams';
import { CONST } from '@/shared/utils/Constants';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
// import { DefaultViewHelper } from '../ViewHelper';
import GeneralUtils from '@/shared/utils/generalUtils';
import D4yTable from '@/components/d4y-table/d4y-table.vue';

const name = 'gspAttributes-view';
const gspAttributeModule = namespace('gspAttribute');

@Component({ name: name, components: { D4yTable } })
export default class GspAttributesComponent extends Vue {
  @gspAttributeModule.Action('getGspAttributes') private actionGetgspAttributes!: any;
  @gspAttributeModule.Getter('getGspAttributes')
  private gspAttributes!: OdataItems<GspAttribute>;
  @gspAttributeModule.Getter('getGspAttributesSearchParams')
  private gspAttributesSearchParams!: SearchParams;
  @gspAttributeModule.Getter('getGspAttributesIsLoading')
  private gspAttributesIsLoading!: boolean;
  @gspAttributeModule.Action('deleteGspAttribute')
  private actionDeleteGspAttribute!: any;

  async mounted() {
    var promiseAll = [this.getGspAttributes()];
    await Promise.all(promiseAll);
  }
  private async getGspAttributes() {
    await this.actionGetgspAttributes({ searchParams: this.gspAttributesSearchParams });
  }
  get headers() {
    let headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
      align?: string;
    }[] = [
      { text: this.$t('Name'), value: 'name' },
      {
        text: this.$t('actions'),
        value: 'actions',
        sortable: false,
        width: '1%',
        align: 'center',
      },
    ];
    return headers;
  }

  get image() {
    return {};
  }

  onAdd() {
    this.add();
  }

  add() {
    this.$emit("add-edit", {type: 'attribute', id: 0})
    // this.$router.push({
    //   name: ROUTES.gspAttribute,
    //   params: {
    //     gspAttributeId: CONST.emptyGuid,
    //   },
    // });
  }

  editGspAttribute(editGspAttribute: GspAttribute) {
    this.$emit("add-edit", {type: 'attribute', id: editGspAttribute.id })
    // this.$router.push({
    //   name: ROUTES.gspAttribute,
    //   params: { gspAttributeId: editGspAttribute.id },
    // });
  }

  deleteGspAttribute(editedGspAttribute: GspAttribute) {
    this.$confirm
      .open(
        `${this.$t('delete_dialog_title')}`,
        `${this.$t('delete_dialog_text')}`,
        {
          cancelText: this.$t('delete_dialog_cancel'),
          okText: this.$t('delete_dialog_ok'),
        }
      )
      .then(async (response: any) => {
        if (response) {
          await this.actionDeleteGspAttribute(editedGspAttribute.id).then(() => {
            this.getGspAttributes();
          });
        }
      });
  }

  get isRealXl() {
    return this.$vuetify.breakpoint.width < 1920;
  }

  get getFilterFields() {
    return this.gspAttributesSearchParams.orClauseFieldsIds
      .map(x => this.$t(GeneralUtils.camelToSnakeCase(x)))
      .toString();
  }

  private async gspAttributesUpdateOptions(newVal: any, oldVal: any) {
    this.gspAttributesSearchParams.dataOption = newVal;
    this.gspAttributesSearchParams.filter = newVal.filter;
    if (newVal != oldVal && !this.gspAttributesIsLoading) this.getGspAttributes();
  }
}
