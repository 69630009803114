import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import { OdataItems } from '@/shared/model/OdataItems';
import { SearchParams } from '@/shared/model/searchParams';
import gspServ, { GspService } from '@/shared/model/gspService';
import { GspRole } from '@/shared/model/gspRole';
import gspRoleD, { GspRoleData } from '@/shared/model/gspRoleData';
import gspMod, { GspModule } from '@/shared/model/gspModule';
import gspAttr, { GspAttribute } from '@/shared/model/gspAttribute';

const logger = new Logger('gsp-role-data-dialog');
const gspRoleManagement = namespace('gspRole');
const gspAttributeModule = namespace('gspAttribute');
const gspModuleModule = namespace('gspModule');
const gspServiceModule = namespace('gspService');
const gspRoleDataModule = namespace('gspRoleData');

@Component({
  components: {},
  name: 'gsp-role-data-dialog',
})
export default class GspRoleDataDialog extends Vue {
  @Ref('observer-gsp-role-data-dialog-form')
  private observerFilterDialogForm!: any;

  @gspServiceModule.Action('getGspServices') private actionGetGspServices!: any;
  @gspModuleModule.Action('getGspModules') private actionGetGspModules!: any;
  @gspModuleModule.Getter('getGspModulesSearchParams')
  private gspModulesSearchParams!: SearchParams;

  @gspRoleManagement.Action('updateGspRole')
  private actionUpdateGspRole!: any;
  @gspRoleManagement.Getter('getGspRole')
  private role!: GspRole;

  @gspAttributeModule.Action('getGspAttributes') private actionGetGspAttributes!: any;

  @gspServiceModule.Getter('getGspServices')
  private gspServices!: OdataItems<GspService>;
  @gspAttributeModule.Getter('getGspAttributes')
  private gspAttributes!: OdataItems<any>;

  @gspRoleDataModule.Action('getGspRolesData')
  private actionGetGspRolesData!: any;
  @gspRoleDataModule.Action('updateGspRoleData')
  private actionUpdateGspRoleData!: any;
  @gspRoleDataModule.Getter('getGspRolesDataSearchParams')
  private gspRolesDataSearchParams!: any;

  @Prop({ default: false })
  private dialog!: boolean;

  @Prop()
  private model!: any;


  roleData: any = {};
  modulesLoaded = false;

  servicesArr: any = [];
  modulesArr: any = [];
  attributesArr: any = [];

  async mounted() {
    var promiseAll = [this.getGspServices(), this.getAttributes()];
    await Promise.all(promiseAll);
  }

  onChangeService(e: any) {
    this.actionGetGspModules({ searchParams: this.gspModulesSearchParams, serviceId: e })
      .then((result: any) => {
        console.log('result onChangeService modules:>> ', result);
        this.modulesArr = result.value;
        this.modulesLoaded = true;
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  private async getGspServices() {
    await this.actionGetGspServices()
      .then((result: any) => {
        this.servicesArr = result.value;
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }
  private async getAttributes() {
    await this.actionGetGspAttributes()
      .then((result: any) => {
        this.attributesArr = result.value;
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  get services() {
    return this.gspServices.items;
  }

  get modules() {
    return this.modulesArr;
  }

  isSaving = false;
  isLoading = false;

  async saveRoleData() {
    // console.log('this.roleData :>> ', this.roleData);
    // console.log('this.role :>> ', this.role);
    // console.log('this.ser :>> ', this.servicesArr);
    // console.log('this.mm :>> ', this.modulesArr);
    // console.log('this.attr :>> ', this.attributesArr);

    const attrIds = this.roleData?.attributes;
    let payloadAttr = this.attributesArr.filter((x: any) => attrIds?.includes(x.id));

    this.isLoading = true;
    const result = await this.observerFilterDialogForm.validate();
    if (!result) {
      this.isLoading = false;
      return;
    }
    
    payloadAttr = payloadAttr.map((x: any) => gspAttr.toAPI(x));

    let payload: GspRoleData = gspRoleD.parse({});
    payload.roleId = this.role.id;
    payload.serviceId = this.roleData?.service;
    payload.moduleId = this.roleData?.module;
    payload.attributes = payloadAttr;

    await this.actionUpdateGspRoleData(payload).then((resp: GspRoleData) => {
      this.isLoading = false;
      console.log('resp :>> ', resp);
      this.actionGetGspRolesData({searchParams: this.gspRolesDataSearchParams, roleId: this.model.id})
      this.clickClose();
    });
  }

  clickClose() {
    this.$emit('click:close');
  }

  closeDialog() {
    this.clickClose();
  }

}
