import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import gspServiceForm from './../../gspServices/gspService/gspService.vue';
import gspModuleForm from './../../gspModules/gspModule/gspModule.vue';
import gspAttributeForm from './../../gspAttributes/gspAttribute/gspAttribute.vue';

const logger = new Logger('gsp-add-edit-dialog');
const gspAttributeModule = namespace('gspAttribute');
const gspModuleModule = namespace('gspModule');
const gspServiceModule = namespace('gspService');

@Component({
  components: { gspServiceForm, gspModuleForm, gspAttributeForm },
  name: 'gsp-add-edit-dialog',
})
export default class FilterDialog extends Vue {
  @Ref('observer-gsp-add-edit-dialog-form')
  private observerFilterDialogForm!: any;

  @gspAttributeModule.Action('getGspAttributes') private actionGetGspAttributes!: any;
  @gspModuleModule.Action('getGspModules') private actionGetGspModules!: any;
  @gspServiceModule.Action('getGspServices') private actionGetGspServices!: any;

  @Prop({ default: false })
  private dialog!: boolean;

  @Prop()
  private value!: any;

  @Prop()
  private type!: any;
  @Prop()
  private emitId!: any;


  tab: any = null;
  text: any = 'test text';

  isSaving = false;

  clickClose() {
    this.updateTableList();
    this.$emit('click:close');
  }

  closeDialog(){
    this.clickClose();
  }

  updateTableList(){
    switch (this.type) {
      case 'service':
          this.actionGetGspServices();
        break;
      case 'module':
          this.actionGetGspModules();
        break;
      case 'attribute':
          this.actionGetGspAttributes();
        break;

      default:
        break;
    }
  }

}
